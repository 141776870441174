import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Blue Skies" />

		<h2>Blue Skies</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST10 blue skies front.jpg"
			alt="Jesse Rivest - Blue Skies - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on March 3, 2022.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			I imagine I used to think this was a Willie Nelson song—his is the version I grew up with (Stardust?).
			Actually, as you may know, it's an Irving Berlin song.
			In the early stages of the COVID-19 pandemic, I decided to take a crack at it with my guitar;
			I even posted a video or two of it on Youtube.
			Late 2021 I decided to start releasing some cover songs, and recently this one put up its hand to be next!
			I felt it mellow; I added in a bit of slide guitar and voilà... I hope you like it!
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Blue Skies (2:59)<br />
				T0700118133 - © Irving Berlin (ASCAP) 1927, Berlin Irving Music Corp (ASCAP), Concord Sounds<br />
				CA-6P8-22-00002 - © Jesse Rivest 2022 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST10, © Jesse Rivest 2022, All Rights Reserved<br />
			UPC/EAN: 0198001666971<br />
			Released: March 3, 2022
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song sung by Jesse Rivest.<br />
			Acoustic and electric guitars performed by Jesse Rivest.<br />
			Album cover photography by Jesse Rivest.<br />
			Album cover layout by Jesse Rivest.
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always, and for enjoying so many blue skies with me.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST10 blue skies back.png"
			alt="Jesse Rivest - Blue Skies - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
